<template>
  <section class="sub-container" ref="sub-container">
    <sub-nav-with-user-info :open="navOpen">
      <sub-nav @toggle="onNavToggle"></sub-nav>
    </sub-nav-with-user-info>
    <main class="sub">
      <router-view></router-view>
    </main>
  </section>
</template>

<script>
//git test
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import SubNav from 'Components/account/partials/SubNav'
export default {
  components: {
    SubNavWithUserInfo,
    SubNav,
  },
  data() {
    return {
      navOpen: false,
    }
  },
  methods: {
    onNavToggle() {
      this.navOpen = !this.navOpen
    },
  },
}
</script>

<style></style>
