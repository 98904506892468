<template>
  <div class="sub-nav __img">
    <p class="tit" ref="tit" @click="onClickTit">{{ TITLE[routeName] }}</p>
    <ul ref="ul">
      <li :class="{ on: routeName === 'accountEdit' }" @click="onClickTit">
        <router-link class="myp-ico01" href="javascript:void(0)" to="/account">
          기본 정보 수정
        </router-link>
      </li>
      <li :class="{ on: routeName === 'accountBank' }" @click="onClickTit">
        <router-link
          class="myp-ico02"
          href="javascript:void(0)"
          to="/account/bank"
        >
          계좌 번호
        </router-link>
      </li>
      <li :class="{ on: routeName === 'accountChangePw' }" @click="onClickTit">
        <router-link
          class="myp-ico03"
          href="javascript:void(0)"
          to="/account/change-pw"
        >
          비밀번호 변경
        </router-link>
      </li>
      <li
        :class="{ on: routeName === 'accountPushSetting' }"
        @click="onClickTit"
      >
        <router-link
          class="myp-ico04"
          href="javascript:void(0)"
          to="/account/push-setting"
        >
          알림 설정
        </router-link>
      </li>
      <li :class="{ on: routeName === 'accountWithdraw' }" @click="onClickTit">
        <router-link
          class="myp-ico05"
          href="javascript:void(0)"
          to="/account/withdraw"
        >
          회원 탈퇴
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      TITLE: Object.freeze({
        accountEdit: '기본 정보 수정',
        accountBank: '계좌 번호',
        accountChangePw: '비밀번호 변경',
        accountPushSetting: '알림 설정',
        accountWithdraw: '회원 탈퇴',
      }),
    }
  },
  methods: {
    onClickTit() {
      //모바일 레이아웃에서만 실행
      if (matchMedia('screen and (max-width: 1024px)').matches) {
        this.$refs['tit'].classList.toggle('open')
        this.$refs['ul'].classList.toggle('on')
        this.$emit('toggle')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.side-wrap .sub-nav {
  > ul > li > a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
